import { useEffect, useMemo, useRef } from 'react';
import { useFormState } from 'react-hook-form';
import _ from 'lodash';
import omitDeep from 'omit-deep-lodash';
import localforage from 'localforage';

const useFormPersist = ({
  name,
  control,
  getValues,
  reset,
  exclude = [],
  onDataInitiallyRestored,
  onDataRestored,
  onTimeout,
  timeout, // clear storage after timeout
  // FIXME: temporary disabled interval, save every 100 minutes
  interval = 6000000, // save every interval
  noRestoreThreshold = 20000, // don't restore if (__timestamp - defaultValues.updatedAt) is less than this
  disabled,
}) => {
  const isRestoreFinishedRef = useRef(false);
  const intervalIdRef = useRef(null);

  const excludeMap = useMemo(
    () =>
      _.reduce(exclude, (acc, key) => ({ ...acc, [key]: true }), {
        updatedAt: true, // we don't want to persist updatedAt
      }),
    [exclude]
  );

  const { defaultValues } = useFormState({ control });

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  const getStorage = () => localforage;

  const clearStorage = () => getStorage().removeItem(name);

  const stopInterval = () => {
    clearInterval(intervalIdRef.current);
  };

  const restore = (values, callBack) => {
    const valuesToRestore = _.merge({}, defaultValues, values);

    reset(valuesToRestore, { keepDefaultValues: true });

    if (callBack) {
      callBack(valuesToRestore);
    }
  };

  const restartInterval = (newInterval, runImmediately) => {
    clearInterval(intervalIdRef.current);

    const intervalToUse = newInterval || interval;

    const save = () => {
      const allValues = getValues();
      const values = _.isEmpty(excludeMap)
        ? allValues
        : omitDeep(allValues, exclude);

      if (!_.isEmpty(values)) {
        values.__timestamp = Date.now();
        getStorage().setItem(name, values);
      }
    };

    // save immediately
    if (runImmediately) {
      save();
    }

    // then save every interval
    intervalIdRef.current = setInterval(save, intervalToUse);
  };

  useEffect(() => {
    if (disabled) {
      stopInterval();
      return;
    }

    getStorage()
      .getItem(name)
      .then((item) => {
        if (!item) {
          restartInterval(interval);
          return;
        }

        const { __timestamp, ...values } = item;
        const now = Date.now();

        if (timeout && now - __timestamp >= timeout) {
          if (onTimeout) {
            onTimeout();
          }
          clearStorage();
          restartInterval(interval, false);
          return;
        }

        restore(values, onDataInitiallyRestored);

        restartInterval(interval, false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, onDataInitiallyRestored, reset, disabled]);

  // when defaultValues.updatedAt changes, we check if we should restore
  useEffect(() => {
    if (disabled || !defaultValues?.updatedAt || isRestoreFinishedRef.current) {
      return;
    }

    // make sure we don't override the current storage
    stopInterval();

    getStorage()
      .getItem(name)
      .then((item) => {
        if (item) {
          const { __timestamp, ...values } = item;

          const updatedAt = new Date(defaultValues.updatedAt).getTime();
          if (updatedAt < __timestamp - noRestoreThreshold) {
            restore(values, onDataRestored);

            isRestoreFinishedRef.current = true;
          }
        }

        restartInterval(interval, true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, disabled]);

  return {
    clearPersist: clearStorage,
    stopPersistInterval: stopInterval,
  };
};

export default useFormPersist;
